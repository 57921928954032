.translation{
  .p-dropdown {
    min-width: 300px;
  }

  &-card{
    .p-card {
      background-color: rgba(0, 164, 241, 0.2);
    }
  }

}
