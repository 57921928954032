.primeng-table {

  &__search-col {
    width: 3rem;
  }

  &__filter-col {
    width: 100%;
  }

  &__calendar-filter-col {
    width: 100px;
  }

  &__edit-row-buttons {
    text-align: right;
  }

  &__align-right {
    text-align: right;
  }

  &__edit-row-component {
    text-align: center
  }

  &__edit-row-padding {
    padding: 0
  }


  &__search-icon {
    @extend pi!optional;
    @extend pi-search!optional;
  }

  &__angle-down {
    @extend pi!optional;
    @extend pi-angle-down!optional;
  }

  &__text-center {
    text-align: center;
  }

}
