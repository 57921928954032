.notification-box {
  &-container {
    z-index: 10000;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  &-list {
    width: 300px;
    max-height: 50%;
    border-collapse: collapse;
    display: table;
  }

  &-elem {
    display: table-row;
    vertical-align: bottom;
    height: 1px;

    &__position {
      @extend .position-static
    }
  }
}
