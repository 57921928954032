///COLORS PALETTE///
$orange: #f39200;
$orange2: #fbdeb2;
$white: #ffffff;
$gray1: #353c37;
$gray2: #eaeaea;
$gray3: #e6e6e6;
$gray4: #f0f1f0;

$green1: #b8f0d1;
$green2: #13ce66;
$red1: #ffc8c8;
$red2: #ff4949;
$yellow1: #ffeec0;
$yellow2: #ffc82c;
$blue: #266dd3;
$blue1: #bce9ff;
$blue2: #1fb6ff;
$blue-white: #d3d8ec;

///COLORS USAGES///

:root {
  /* Colors */
  --primary-color-bv: $blue2;
  --secondary-color-bv: $gray2;
  --hoover-color-bv: #a8ecff;
  --sidebar-color-bv: $gray2;
}

$project-font-color: $gray1 !default;
$project-background-color: $blue-white !default; // The color of the main background.

$project-primary-color: $blue2 !default;
$project-primary-hover: lighten($project-primary-color, 20%);
$project-primary-active: darken($project-primary-color, 10%);
$project-primary-disabled: lighten($project-primary-color, 30%);

$project-secondary-color: $gray2 !default;
$project-secondary-hover: lighten($project-secondary-color, 10%);
$project-secondary-active: darken($project-secondary-color, 10%);
$project-secondary-disabled: lighten($project-secondary-color, 30%);

$project-hr-color: $gray3;

$project-item-hover: lighten($project-primary-color, 5%);
$project-item-active: darken($project-primary-color, 5%);

$project-title-background-color: $orange2;
$project-title-color: $blue;

$project-alert-color-success: $green2;
$project-alert-color-warning: $yellow2;
$project-alert-color-error: $red2;
$project-alert-color-info: $blue2;

$project-alert-bgc-success: $green1;
$project-alert-bgc-warning: $yellow1;
$project-alert-bgc-error: $red1;
$project-alert-bgc-info: $blue1;

$project-input-control-border-color: $gray3 !default;
$project-input-control-disabled-color: #ececec !default;
$project-table-row-background-color: $gray4;

$project-input-hover: $gray3;
///OTHER///
$project-input-control-border: 2px solid $project-input-control-border-color;
$project-border-invalid: 2px solid $red2;

$project-header-height: 3rem;
$project-controls-height: 30px;
$base-border-radius: .375rem;

$project-danger: #d42e18;


