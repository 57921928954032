.debug-bg-color {
  background-color: aqua;
}

.no-padding {
  padding: 0;
}

.whole-width {
  width: 100%;
}
