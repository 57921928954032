html, body {
  height: 100%;
}
.modal-content{
  color: black;
}
body {
  background-color: #17212f;
  color: rgba(255, 255, 255, 0.87);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, Roboto, Helvetica, Arial, sans-serif, “Apple Color Emoji”, “Segoe UI Emoji”, “Segoe UI Symbol”;
}

mat-sidenav-container[_ngcontent-c4] {
  display: flex;
  flex-direction: column;
  background-color: blue;
  /* height: 100vh; */
  height: calc(100vh - 64px - 49px);
}

.global-align {
  &__align-content-start {
    @extend .align-content-start;
    @extend .form-field;
    @extend .text-align-left
  }

  &__align-content-center {
    @extend .align-content-center
  }

  &__align-content-end {
    @extend .align-content-end
  }
}

.global-justify {
  &__center {
    @extend .d-flex;
    @extend .justify-content-center;
  }
}

.global-input {
  @extend .form-control;
}

.global-overlay-hover {
  background-color: var(--primary-color-bv);
  border: none;
  color: var(--secondary-color-bv);
  padding: 16px 32px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  transition: 0.4s;
}

.global-overlay-hover:hover {
  background-color: var(--hoover-color-bv);
  color: var(--secondary-color-bv);;
}

.global-underscore-border {
  border-bottom: 3px solid $blue;
}

/**, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

*[hidden] {
  display: none !important;
}

html {
  height: 100%;
}

body {
  background-color: $project-background-color;
  height: 100%;
  margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1, h2, h3 {
  color: $project-primary-color;
  padding: .5rem .25rem;
  font-weight: normal;
}

h1 {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

h2 {
  @include hr-abs-bottom();
  font-size: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

h3 {
}



li {
  list-style: none;
}

a {
  text-decoration: none;
  transition: .1s ease-in-out;
  transition-property: background-color, color, box-shadow;
  color: $project-primary-color;

  &:hover {
    color: $project-primary-hover;
  }

  &:active {
    color: $project-primary-active;
  }
}*/

////////////////////////////
///////// BUTTONS //////////
////////////////////////////

//button {
//  @include base-control;
//  @include control-hover();
//  @include control-active();
//  @include control-disabled();
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  height: $project-controls-height;
//  padding: .25rem .625rem;
//  color: white;
//  background-color: $project-primary-color;
//  cursor: pointer;
//}

////////////////////////////
////////// SELECT //////////
////////////////////////////

//select {
//  @include base-control;
//  @include control-active(white, black);
//  padding-left: .25rem;
//  background-color: white;
//  cursor: pointer;
//  height: $project-controls-height;
//  border: $project-input-control-border;
//}

////////////////////////////
/////////// INPUTS /////////
////////////////////////////

//input, textarea {
//  @include base-control;
//  padding: 0 0 0 .5rem;
//  border: $project-input-control-border;
//  height: $project-controls-height;
//}
//
//textarea {
//  height: 160px;
//}

//input[type="checkbox"], input[type="radio"] {
//  align-self: center;
//  flex-shrink: 0;
//  margin: 0;
//  padding: 0;
//  overflow: hidden;
//  height: 25px !important;
//  width: 25px !important;
//  flex-basis: 25px !important;
//  position: relative;
//  border: 1px transparent;
//  -moz-appearance: none;
//  -webkit-appearance: none;
//  appearance: none;
//
//  &:focus {
//    box-shadow: none;
//  }
//
//  &:disabled ~ label {
//    color: $project-input-control-disabled-color;
//  }
//
//  &::after {
//    border: $project-input-control-border;
//    content: ' ';
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    position: absolute;
//    top: 0;
//    left: 0;
//    bottom: 0;
//    right: 0;
//    transition: .1s ease-in-out;
//    transition-property: background-color, color;
//    background: white;
//  }
//
//  &:hover::after {
//    background-color: $project-input-hover;
//    color: $project-input-hover;
//  }
//
//  &:disabled::after {
//    background-color: $project-input-control-disabled-color;
//  }
//
//  &:checked {
//    &::after {
//      color: white;
//      background-color: $project-primary-color;
//    }
//
//    &:hover::after {
//      background-color: $project-primary-hover;
//    }
//
//    &:disabled::after {
//      background-color: $project-primary-disabled;
//    }
//  }
//}

//input[type="checkbox"] {
//  cursor: pointer;
//
//  &::after {
//    border-radius: 0.15rem !important;
//  }
//
//  &:checked::after {
//    //@include icon-settings("\f00c", bold);
//    padding: 4px;
//    font-size: 15px;
//  }
//}

//input[type="radio"] {
//  border-radius: 50% !important;
//
//  &::after {
//    border-radius: 50% !important;
//  }
//
//  &:checked::after {
//    @include icon-settings("\f111", bold);
//    padding: 8px;
//    font-size: 7px;
//  }
//}

input[type="file"] {
  display: none;
}

audio {
  min-width: 0;
  padding: 0;
}

////////////////////////////
///////// SCROLLBAR ////////
////////////////////////////

::-webkit-scrollbar {
  width: 10px;

  &-track {
    background: var(--primary-color-bv);
  }

  &-thumb {
    @include control-hover;
    @include control-active;
    background: var(--primary-color-bv);
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--hoover-color-bv);
}

.customBreadcrumb .p-breadcrumb {
  border: none !important;
  margin-bottom: 10px;
  span {
    margin-left: 5px;
  }
}

.p-datepicker {
  width: 300px !important;
}

.margin {
  margin: 10px;
}

.margin-small {
  margin: 5px;
}

.text-align-left {
  text-align: left;
}

.margin-t {
  margin-top: 15px;
}
