.login {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 25%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  .p-card{
    background-color: rgb(31 45 64 / 80%);
    border-radius: 7px;
  }


  &__login-container {
    @extend .pl-4;
    @extend .pr-4;
  }
  &__form-control {
    @extend .form-control;
  }

  &__logo {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    height: 100%;
    width: 100%;
    background-image: url('../../assets/back.jpg');
  }

  &__float-right {
    @extend .float-right;
  }
  &__float-left {
    @extend .float-left;
  }

}

.logo {
  height: 100px;
  width: 100px;
  margin-top: 20px;
}
.logo-image {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url('../../assets/back.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
