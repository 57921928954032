@import '~primeng/resources/themes/vela-blue/theme.css';
@import "~bootstrap";
@import '../node_modules/bootstrap/scss/bootstrap';
@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import "src/css/vars";
@import "src/css/mixins";
@import "src/css/utils";
@import "src/css/global";
@import "src/css/modules";
@import "src/css/base";
@import "src/css/primeng-extensions";
