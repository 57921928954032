.base {
  &-nav {
    @extend .nav;
  }

  &-nav-tabs {
    @extend .nav-tabs;
  }

  &-tab {
    &-title {
      padding: 10px 15px 2px;
      margin-right: 2px;
    }

    &-active {
      @extend .active;
      color: #555;
      cursor: default;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &-pane {
      padding: 1em;
    }
  }
}
