.notification {
  &-message {
    flex-grow: 8;

    &__col-sm-10 {
      @extend .col-sm-10;
    }

    &__pr-0 {
      @extend .pr-0;
    }
  }

  &-click {
    &__col-sm-2 {
      @extend .col-sm-2;
    }
  }

  &-close {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    color: #1f2d40;
  }

  &-wrap {
    display: flex;
    flex-direction: row;
  }
}
