.base-table {
  @extend .table;

  &__sm {
    @extend .table-sm;
  }

  &__striped {
  @extend .table-striped;
}
  &__row-on-hover {

  }
  &__justify {
    @extend .justify-content-center;
  }
  &__block {
    @extend .d-block;
  }
  &__pagination {
    @extend .pagination;
  }
  &__page-item {
    @extend .page-item;
  }
  &__page-link {
    @extend .page-link;
    @extend .pointer !optional;
  }
  &__inline {
    @extend .d-inline;
    @extend .m-r-20 !optional;
  }
  &__col {
    @extend .col;
  }
  &__flex {
    @extend .d-flex;
  }
  &__header-text {
    @extend .text-white
  }
  &__header-background {
    background-color: #266dd3;
  }
  &__items-align {
    @extend .mb-3;
    @extend .p-7 !optional;
    @extend .align-items-center;
  }
  &__selected-item {
    background-color: #1fb6ff;
  }
  &__editCol {
    width:10vw
  }
}
