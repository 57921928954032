.roles {

  .p-tree-toggler {
    display: none !important;
  }

  .p-tree {
    border: 0;
  }

  .p-component {
    background-color: var(--sidebar-color-bv);
  }

  &-max-height {
    height: 100%;
  }

}
.groups {

  margin-top: 1rem;

  table {
    width: 100%;
  }

  &__table-outside {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12)
  }

  &__functionalities {
    @extend .row;
    @extend .justify-content-center;
    display: flex;
  }

  &__functionalities_tables {
    @extend .col-5;
    @extend .mt-3;
  }

  &__table_name {
    text-align: center;
  }

  &__btn {
    @extend .col-1;
    margin-top: auto !important;
    margin-bottom: auto;
  }

  &__row {
    @extend .row
  }

  %material-btn {
    @extend .col-11;
    margin: auto;
  }

  &__btn_mt2 {
    @extend %material-btn;
    @extend .mt-2
  }

  &__btn_mt3 {
    @extend %material-btn;
    @extend .mt-3
  }

  &__submit {
    @extend .col;
    @extend .float-right;
    @extend .pr-0;
    @extend.mt-3;
    margin-right: 4.16% !important
  }

  &__submit_btn {
    @extend .float-right;
  }

  &__select{
    @extend .ml-1
  }
}

