$grid-form-gap: 6.5vw;

@mixin grid-form($col-nr: 3, $row-nr: 6, $flow: false) {
  display: grid;
  grid-template-columns: repeat($col-nr, 1fr);
  grid-template-rows: repeat($row-nr, auto);
  grid-column-gap: $grid-form-gap;
  grid-row-gap: .5rem;

  @if ($flow) {
    grid-auto-flow: $flow;
  }

  .field {
    padding: 0;
  }
}

@mixin grid-empty-cell($first-area: false, $second-area: false) {
  @if ($first-area) {
    &::before {
      content: '';
      grid-area: unquote($first-area);
    }
  }
  @if ($second-area) {
    &::after {
      content: '';
      grid-area: $second-area;
    }
  }
}

@mixin grid-sub-form($col-nr: 1, $row-nr: 1) {
  @include grid-form($col-nr, $row-nr);
  height: 100%;
}

@mixin card($padding: 2rem) {
  background-color: white;
  padding: $padding;
  @include box-shadow();

  @include mq(medium) {
    @include box-shadow($blur-radius: .5rem)
  }

  @include mq(mobile) {
    padding: 1rem;
  }
}

@mixin individual-card() {
  @include card;
  width: 550px;
  padding: 1.5rem 2rem;
  margin: 2rem auto 0;

  @include mq(tablet) {
    width: 100%;
    margin: 2rem 0 0;
  }
}

@mixin box-shadow($offset-x: .25rem, $offset-y: .5rem, $blur-radius: 1.5rem, $spread-radius: 0, $color: rgba(0, 0, 0, .25)) {
  box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
}


@mixin multi-input($first-tag: 'input', $last-tag: 'input', $width: false, $width-second: false) {
  > #{$first-tag}:not(:last-of-type), > #{$first-tag}:first-of-type {
    border-radius: $base-border-radius 0 0 $base-border-radius !important;
    border-right-width: 0 !important;
    z-index: 1; // for box-shadow on focus - without it it would be covered by following element
  }
  > #{$last-tag}:not(:first-of-type), > #{$last-tag}:last-of-type {
    border-radius: 0 $base-border-radius $base-border-radius 0 !important;
  }

  @if ($width) {
    label ~ * {
      flex-basis: $width !important;
    }

    @if ($width-second) {
      > #{$last-tag}:not(:first-of-type), > #{$last-tag}:last-of-type {
        flex-basis: $width-second !important;
      }
    }
  }

  @content;

}

@mixin base-control {
  @include control-disabled($project-input-control-disabled-color);
  border-radius: .375rem;
  outline: none;
  border: 1px solid transparent;
  transition: .1s ease-in-out;
  transition-property: background-color, color, box-shadow;
  color: $project-font-color;
  font-size: 1rem;

  &:focus {
    box-shadow: 0 0 3px $project-primary-color;
  }
}

@mixin list-item {
  background-color: white;
  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: .75rem;
  padding: .5rem 1rem;
}

////////////////////////////
// HORIZONTAL RULE (<hr>) //
////////////////////////////

@mixin hr-color($background-color) {
  &::before, &::after {
    background-color: $background-color;
  }
}

@mixin hr-abs-top($margin-top: 1rem, $width : 100%, $height: 1px, $bgc: $project-secondary-color) {
  position: relative;
  margin-top: $margin-top;

  &::before {
    position: absolute;
    width: $width;
    height: $height;
    top: -$margin-top/2;
    left: (100%-$width)/2;
    content: '';
    background-color: $bgc;
  }
}

@mixin hr-abs-bottom($margin-bottom: 1rem, $bottom: false, $width : 100%, $height: 1px, $bgc: $project-secondary-color) {
  position: relative;
  margin-bottom: $margin-bottom;

  &::before {
    position: absolute;
    width: $width;
    height: $height;
    @if ($bottom) {
      bottom: $bottom;
    } @else {
      bottom: -$margin-bottom/2
    }
    left: (100%-$width)/2;
    content: '';
    background-color: $bgc;
  }
}

@mixin hr($margin, $bgc: $project-secondary-color, $height: 1px) {
  margin: $margin;
  content: '';
  height: $height;
  width: 100%;
  display: block;
  background-color: $bgc;
}

@mixin hr-bottom($margin: 1rem 0) {
  flex-wrap: wrap; // takes effect when parent element has display: flex;

  &::after {
    @include hr($margin);
  }
}

@mixin hr-between($margin-top: 1rem, $width : 100%, $height: 1px, $bgc: $project-secondary-color) {
  position: relative;
  &:nth-of-type(n + 2) {
    margin-top: $margin-top;

    &::before {
      position: absolute;
      width: $width;
      height: $height;
      top: -$margin-top/2;
      left: (100%-$width)/2;
      content: '';
      background-color: $bgc;
    }
  }
}

@mixin hr-between-children($margin: 1rem, $width: 100%, $height: 1px, $bgc: $project-hr-color, $child-selector: '*') {
  & > #{$child-selector} {
    display: block;
    @include hr-between($margin, $width, $height, $bgc);
  }
}

///ICONS & BUTTONS///

@mixin icon-self($glyph, $weight: 300, $font-size: 1rem, $padding: 0, $margin: 0, $color: inherit, $background-color: inherit) {
  @include icon-settings($glyph, $weight, $font-size);
  padding: $padding;
  margin: $margin;
  color: $color;
  background-color: $background-color;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

@mixin icon-before($glyph, $weight: 300, $font-size: 1.25rem, $padding: 0, $margin: 0, $color: inherit, $background-color: inherit) {
  &::before {
    @include icon-self($glyph, $weight, $font-size, $padding, $margin, $color, $background-color);
  }
}

@mixin icon-after($glyph, $weight: 300, $font-size: 1.25rem, $padding: 0, $margin: 0, $color: inherit, $background-color: inherit) {
  &::after {
    @include icon-self($glyph, $weight, $font-size, $padding, $margin, $color, $background-color);
  }
}

@mixin icon-settings($glyph, $weight: 300, $font-size: 1rem) {
  font-family: "Font Awesome 5 Pro";
  line-height: 1;
  font-weight: $weight;
  font-size: $font-size;
  content: $glyph;
}

@mixin icon-button($glyph, $reverse-mode: false, $weight: 300, $size: 1rem) {
  @include icon($glyph, $weight, $size);
  color: white;
  padding: .875rem - $size/2;
  line-height: 1;

  @if $reverse-mode {
    &::before {
      transform: rotateY(180deg);
    }
  }

  &::before {
    width: $size;
    height: $size;
  }
}

@mixin icon-content-button($glyph, $reverse-mode: false, $weight: 300, $size: 1rem) {
  @include icon-button($glyph, $reverse-mode, $weight, $size);
  display: flex;
  align-items: center;

  padding-left: .625rem;
  padding-right: .625rem;

  font-weight: 600;

  &::before {
    margin-right: .5rem;
  }
}

@mixin icon($glyph, $weight: normal, $font-size: 1rem, $margin: 0) {
  &::before {
    @include icon-settings($glyph, $weight, $font-size);
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    margin: $margin;
  }
}

@mixin icon-header($glyph) {
  @include mq(mobile) {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &::after {
    @include icon-settings($glyph, 300);
    font-size: 2.5rem;
    display: flex;
    place-self: center;

    @include mq(mobile) {
      display: inline-block;
      margin-left: .5rem;
    }
  }
}

@mixin control-hover($background: $project-primary-hover, $color: false, $selector: ':hover') {
  &#{$selector} {
    background-color: $background;
    text-decoration: none;

    @if ($color) {
      color: $color;
    }
  }
}

@mixin control-active($background: $project-primary-active, $color: false, $selector: ':active') {
  &#{$selector} {
    background-color: $background;

    @if ($color) {
      color: $color;
    }
  }
}

@mixin control-disabled($background: $project-primary-disabled, $color: false, $selector: ':disabled') {
  &#{$selector} {
    background-color: $background;
    cursor: default;

    @if ($color) {
      color: $color;
    }
  }
}

@mixin button($base-color, $hover-color: $white) {
  background-color: $base-color;
  @include control-hover(lighten($base-color, 10%), $hover-color);
  @include control-active(darken($base-color, 10%));
  @include control-disabled(lighten($base-color, 30%));
}

@mixin button-secondary {
  @include button($project-secondary-color);
}

@mixin button-danger {
  @include button($project-danger);
}

@mixin field($self-basis: 100%, $label-basis: 50%, $label-siblings-basis: 50%, $selector: '.field', $important: false ) {
  #{$selector} {
    flex-basis: $self-basis if($important, !important, null);

    > label {
      flex-basis: $label-basis if($important, !important, null);
    }

    @if ($label-siblings-basis == 'fit') {
      > label ~ * {
        flex-basis: auto if($important, !important, null);
        flex-grow: 1;
      }
    } @else {
      > label ~ * {
        flex-basis: $label-siblings-basis if($important, !important, null);
      }
    }


    @content;
  }
}

@mixin editor-theme($element-modificator, $color) {
  .ql-snow.ql-toolbar {
    .ql-picker-label#{$element-modificator}, .ql-picker-item#{$element-modificator}, button#{$element-modificator} {
      color: $color;

      .ql-stroke {
        stroke: $color;
      }

      .ql-fill {
        fill: $color;
      }
    }
  }
}
