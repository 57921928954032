.button {
  @extend .btn;

  &__standard {
    @extend .btn--standard !optional;
  }

  &__outline-secondary {
    @extend .btn-outline-secondary;
  }
}




.fa-button-first-color {
  background-color:  var(--primary-color-bv);
  color:  var(--secondary-color-bv);

  &:hover {
    background-color:  var(--hoover-color-bv);
    color: var(--secondary-color-bv);
  }

  &:disabled {
    pointer-events: none;
  }
}

