.fa_modal {

  &__text_center{
    @extend .text-center
  }

  &__inline{
    display: inline-block;
  }

}
