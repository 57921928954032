////////////////////////////
///////// NAVBAR ////////
////////////////////////////
.navbar {
  background-color: #1f2d40;
  padding-bottom: 0px;

  &__align-content-start {
    @extend .align-content-start
  }
  &__align-content-center {
    @extend .align-content-center
  }
  &__align-content-end {
    @extend .align-content-end
  }
  &__margin {
    margin-right: 3px;
    margin-left: 3px;
  }

  //.p-button {
  //  background: white;
  //  border: 0;
  //}

  //.pi-bars:before {
  //  color: #999999;
  //}
}




////////////////////////////
///////// SIDENAV ////////
////////////////////////////


.sidenav-container {
  flex: 1;
  min-width: 240px;
  .sidenav {
    a {
      display: block;
      padding: 6px 24px;
      width: 100%;
      text-align: left;
    }
  }
  main {
    padding: 18px 24px;
  }
}

