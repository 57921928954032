.pe-card {
  position: inherit;
  margin-bottom: 0.5rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  &-concave {
    background: rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.125);
    border: none;
  }
}

.pe-box {
  background-color: var(--surface-e);
  //text-align: center;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);

  &-label {
    align-self: flex-end;
  }
}

.pe-button-row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  button {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.pe-field:first-child {
  //@extend .p-field;

  span.p-float-label {
    margin-top: 2rem;
  }
}

.pe-arrow-button {
  cursor: pointer;
  font-size: 2em;
}
