.sidebar {
  margin: 0;
  padding: 0;
  background-color: #1f2d40;
  height: 100%;
  min-width: 250px;

}

.main{
  //margin-left: 200px;
}

.sidebar-width {
  //width: 190px;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: var(--secondary-color-bv);
  color: var(--primary-color-bv);
}

.sidebar a:hover:not(.active) {
  background-color: var(--hoover-color-bv);
  color: var(--primary-color-bv);
}



@media screen and (max-width: 700px) {
  .sidebar {
    //width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
