.spinner {
  border: 16px solid #13ce66;
  border-top: 16px solid #f0f1f0;
  display: none;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  position: relative;
  top: 50%;
  width: 100px;
  height: 100px;
  animation: bv-spin 1s linear infinite;
}
