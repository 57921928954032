.form {
  display: flex;
  flex-direction: column;

  &-field {
    width: 40%;

  }

  &-button {
    width: 20%;
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &-standalone {
    width: 20%;
    padding-bottom: 14%;
  }

  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
