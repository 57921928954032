.dark-panel.p-panel {
  .p-panel-titlebar {
    background: #212121;
  }
}

.only-icon.p-calendar.p-inputtext {
      display: none !important;
    }



.active-day {
  background-color:  var(--hoover-color-bv);
  color: var(--secondary-color-bv);
}

.input-height {
  height: 35px;
}

.margin-t {
  margin-top: 25px;
}
.calendar-container .p-calendar .p-inputtext {
  display: none;
}

.calendar-container .p-calendar-w-btn .p-datepicker-trigger {
  height: 35px;
}

.calendar-container-right .p-datepicker{
  width: 475px!important;
}

.calendar-container-left .p-datepicker{
  width: 475px!important;
  left: -470px!important;
  top: -150px!important;
}

.calendar-input {
  height: 30px;
  width: 140px;
}

.align-elements{
  text-align: right;
}
.wrapper {
  display: flex;
}

.calendar-input-mask .p-inputmask .p-inputtext {
  height: 30px;
  width: 140px;
}

.calendar-input-mask .p-inputtext {
  width: 125px;
}
